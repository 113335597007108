$.fn.hasAttr = function (name) {
    return this.attr(name) !== undefined;
};

function updateRoomSelect(id) {
    timeSlotSelect = $('#timeSlot_' + id);
    roomSelect = $('#room_' + id);

    $('#room_' + id + ' option').each(function () {
        $(this).removeAttr('disabled').show();
    });

    $.each(roomTimeSlotCombinations, function (key, object) {
        if ($(timeSlotSelect).val() == object.time_slot_id) {
            $("#room_" + id + " option[value=" + object.room_id + "]").attr('disabled', 'disabled').hide();
        }
    });
}

function updateTimeSlotSelect(id) {
    timeSlotSelect = $('#timeSlot_' + id);
    roomSelect = $('#room_' + id);

    $('#timeSlot_' + id + ' option').each(function () {
        $(this).removeAttr('disabled').show();
    });

    $.each(roomTimeSlotCombinations, function (key, object) {
        if ($(roomSelect).val() == object.room_id) {
            $("#timeSlot_" + id + " option[value=" + object.time_slot_id + "]").attr('disabled', 'disabled').hide();
        }
    });
}

function addCoauthorForm() {
    var container = $('#coauthor-form-container');
    var newform = $('#_coauthor-form_template').clone();
    var nextIndex = $(document).find('[id^=coauthor-form_]').size();

    $(newform).attr('id', 'coauthor-form_' + nextIndex);


    $(newform).find('[id^=coauthors]').each(function (key, element) {
        if ($(element).hasAttr('id')) {
            newid = $(element).attr('id').replace('template', nextIndex);
            $(element).attr('id', newid);
        }

        if ($(element).hasAttr('name')) {
            newname = $(element).attr('name').replace('template', nextIndex);
            $(element).attr('name', newname);
        }

        if ($(element).hasAttr('for')) {
            newfor = $(element).attr('for').replace('template', nextIndex);
            $(element).attr('for', newfor);
        }

        if ($(element).hasAttr('value') && $(element).val() == 'template') {
            $(element).val(nextIndex);
        }

    });

    $(container).append(newform);
    $('#coauthors_count').val(nextIndex + 1);

    addOrderToCoauthorForm();
}

function removeCoauthorForm(btId) {
    var coauthorFormId = btId.split(".")[1];

    var container = $('#coauthor-form-container');

    var lastIndex = $(document).find('[id^=coauthor-form_]').size() - 1;

    $(container).find('#' + coauthorFormId).remove();
    $('#coauthors_count').val(lastIndex);

    addOrderToCoauthorForm();
}

function addOrderToCoauthorForm(){
    $('.coauthor-form').each(function(index){
        if(this.id != '_coauthor-form_template'){
            console.log(this.id);
            console.log($(this).find('.coauthorsOrder')[0]);

            $($(this).find('.coauthorsOrder')[0]).val(index);
        }
    });
}


function charLimit(element, limit, fields) {
    var stringLength = 0;

    $(fields).each(function (index, field) {
        stringLength += countSymbols($('#' + field).val());
    });

    if (element != null && stringLength > limit) {
        var elementTextLength = countSymbols($(element).val());
        var overrun = stringLength - limit;
        var cutOff = elementTextLength - overrun;

        $(element).val($(element).val().substr(0, cutOff));
        stringLength = limit;
    }

    $(fields).each(function (index, field) {
        $('#' + field + 'CharCount').text(stringLength + ' / ' + limit);
    });
}

function countSymbols(string) {
    console.log("cnt");
    return [...string].length;
}

function toggleFormVisibility(checkbox, $formId) {
    $('#' + $formId).find('input').val('');
    $('#' + $formId).find('select').val('');

    if ($(checkbox).prop('checked')) {
        $('#' + $formId).hide('slow');
    } else {
        $('#' + $formId).show('slow');
    }
}

function confirmDeleteAbstract(title, id, $currentPrefix) {
    return smoke.confirm("Do you really want to delete the abstract \"" + title + "\"?", function (e) {
        if (e) {
            window.location.href = $currentPrefix + '/abstracts/' + id + '/delete';
        } else {
        }
    }, {
        ok: "Yes",
        cancel: "No",
        classname: "custom-class",
        reverseButtons: true
    });
}

function confirmDeleteOrganizedSession(title, id, $currentPrefix) {
    return smoke.confirm("Do you really want to delete the organized session \"" + title + "\"?", function (e) {
        if (e) {
            window.location.href = $currentPrefix + '/organized-sessions/' + id + '/delete';
        }
    }, {
        ok: "Yes",
        cancel: "No",
        classname: "custom-class",
        reverseButtons: true
    });
}

function confirmDelete(title, url) {
    return smoke.confirm("Do you really want to delete \"" + title + "\"?", function (e) {
        if (e) {
            window.location.href = url;
        }
    }, {
        ok: "Yes",
        cancel: "No",
        classname: "custom-class",
        reverseButtons: true
    });
}

function scrollToContent() {
    $('html, body').animate({
        scrollTop: $("#contentWrapper").offset().top
    }, 800);
}

function expandTextPreview($id, $complete) {
    $('#' + $id).removeClass('textPreview', {
        children: true,
        duration: 700,
        complete: $complete
    })
}

function reduceTextPreview($id, $complete) {
    $('#' + $id).addClass('textPreview', {
        children: true,
        duration: 700,
        complete: $complete
    })
}

function showAbstractInfo($id) {
    hideAbstractInfo();

    $('#' + $id).fadeIn(250);
}

function hideAbstractInfo($id) {
    if ($id) {
        $('#' + $id).fadeOut(250);
        return;
    }

    $('.abstractPreview').each(function (key, element) {
        $(element).hide();
    });
}

function filterUserList(userListID, filterInputID) {
    $("#" + filterInputID).keyup(function () {
        filter = new RegExp($(this).val(), 'i');

        //$('.userList').each(function(){
            $('.userList tbody tr').filter(function () {
                $(this).each(function () {
                    found = false;
                    $(this).children().each(function () {

                        if(this.localName == "th"){
                            found = true;
                            return;
                        }

                        content = $(this).html();
                        if (content.match(filter)) {
                            found = true
                        }
                    });
                    if (!found) {
                        $(this).hide();
                    }
                    else {
                        $(this).show();
                    }
                });
            });

        //});
    });
}

function registerImageSelectWidget(){
    console.log('register');
    document.querySelectorAll('.image-select-widget').forEach(function (selectWidget) {
        let imageCoordinates = selectWidget.getElementsByClassName('image-coordinates')[0];
        let imageMarker = selectWidget.getElementsByClassName('image-marker')[0];
        let img = selectWidget.getElementsByTagName('img')[0];

        let x = imageCoordinates.value.split(',')[0]
        let y = imageCoordinates.value.split(',')[1]

        let xTranslated = Math.round(img.width / img.naturalWidth * x);
        let yTranslated = Math.round(img.height / img.naturalHeight * y);

        imageMarker.style.left = xTranslated + 'px';
        imageMarker.style.top = yTranslated + 'px';
        imageMarker.style.visibility = 'visible';

        selectWidget.onclick = function (clickEvent) {
            let imageMarker = this.getElementsByClassName('image-marker')[0];
            let imageCoordinates = this.getElementsByClassName('image-coordinates')[0];
            let img = this.getElementsByTagName('img')[0];

            imageMarker.style.left = clickEvent.layerX + 'px';
            imageMarker.style.top = clickEvent.layerY + 'px';
            imageMarker.style.visibility = 'visible';

            let xTranslated = Math.round(clickEvent.layerX / img.width * img.naturalWidth);
            let yTranslated = Math.round(clickEvent.layerY / img.height * img.naturalHeight);

            imageCoordinates.value = xTranslated + ',' + yTranslated;
        }
    })
}
